<template>
<!--详情页面-->
<div>
  <!-- <div style="height:80px;"></div> -->
  <div style="display:flex;justify-content:center;height:90vh;padding-top:80px;overflow:scroll;" class="article_back" id="article_back">
    <div style="background-color:#ffffff;border-radius:10px;">
    <div class="article_body">
      <div id="top_height">
      <div style="display:flex;justify-content:space-between;">
        <div style="display:flex;max-width:700px;">
           <img :src="article_info.img" style="height:210px;min-width:210px;max-width:210px;padding:10px;border-radius:20px;">
            <div style="margin-left:30px;">
                <div style="font-size:28px;font-weight:700;margin-top:46px;margin-bottom:40px;">{{article_info.title}}</div>
                <div style="display:flex;align-items:center;">
                  <img src="~@/assets/img/touxiang.jpg" style="height:26px;border-radius:50%;" >
                  <div style="font-size:20px;margin-left:10px;">baiyu </div>
                  <div style="font-size:16px;color:rgb(138, 135, 135);padding-top:2px;margin-left:10px;">创建时间 : {{article_info.create_time*1 | timeFilter}} &nbsp; 阅读 : {{article_info.look}}</div>
                </div>
          </div>
        </div>

        <div style="width:300px;">
            <div class="return_btn" style="margin-top:20px;margin-left:166px;" @click="goList()">
              <img src="~@/assets/icon/fanhui.png" style="height:18px;width:18px;">
              <div>返回上一级</div>
            </div>

            <div v-if="fabulous==true" class="fabulous_btn" style="margin-left:180px;margin-top:70px;background:rgb(246, 205, 115);" >
              <img src="~@/assets/icon/yizan.png" style="height:25px;width:25px;">
              <div  style="margin-top:6px;margin-left:6px;color:#e51a32;font-weight:700;">{{article_info.like}}</div>
            </div>

            <div v-if="fabulous==false" class="fabulous_btn" style="margin-left:180px;margin-top:70px;background:#f2f2f2;" @click="giveFabulous()">
              <img  src="~@/assets/icon/dianzan.png" style="height:25px;width:25px;">
              <div style="margin-top:6px;margin-left:6px;color:#8a8a8a;font-weight:700;">{{article_info.like}}</div>
            </div>

            <div style="font-size:14px;color:#515151;margin-top:15px;margin-left:122px;">
              <div style="margin-left:56px;">觉得还不错~</div>
              <div style="margin-top:6px;">点个赞吧o(*￣▽￣*)ブ</div>
            </div>
        </div>
      </div>
     
      <div style="height:2px;background:#f2f2f2;margin-bottom:20px;" ></div>
      <div style="font-size:16px;padding:0 20px;color:rgb(138, 135, 135);margin-bottom:10px;">{{article_info.description}}</div>
      <!--文章内容-->
      <div v-html="article_info.content" style="padding:0 20px;min-height:300px;"></div>
      <div style="height:30px;background-color:#FFFFFF;"></div>
      </div>
      </div>
      <!--添加评论-->
      <CommentInfo @heightChange="heightChange" :user_login="user_login" :user_info="user_info" :page_type="2" :article_id="id" :comment_list="comment_list" @getComment="getCommentByArticleId" @quitLogin="quitLogin" @ifLogin="ifLogin"></CommentInfo>
        
    </div>
  </div>
</div>
</template>

<script>
import CommentInfo from './components/CommentInfo.vue';
//引入代码高亮
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'
//代码高亮方法
const highlightCode = () => {
  const preEl = document.querySelectorAll('pre');
  preEl.forEach((el) => {
    hljs.highlightBlock(el);
  })
}
export default {
  components:{
    CommentInfo
  },
  data (){
    return {
        id:'',//文章id
        article_info:{
          fabulous:0
        },//文章详情
        comment_top:0,//评论列表顶部距离
        user_login:0,//用户是否登录
        user_info:{},//用户信息
        comment_list:[],//评论列表
        
        fabulous:false,//是否点了赞
    }
  },
  mounted(){
      this.id =this.$route.query.id;
      this.getArticleInfo();
      this.ifLogin();
      this.getCommentByArticleId();
  },
  updated(){
    highlightCode();
  },
  methods:{
    //添加评论窗口视图高度变化
    heightChange(){
      console.log(document.getElementById('top_height').clientHeight)
      document.getElementById("article_back").scrollTop =  document.getElementById('top_height').clientHeight -50;
    },
    //根据id获得文章详情
    getArticleInfo(){
      this.$axios.get(this.$url.getArticleById + '?id=' + this.id).then(res=>{
        this.article_info = res.data.data;
        highlightCode();//代码高亮
      })
    },
    //退出登录
    quitLogin(){
      window.localStorage.removeItem("user_info");
      this.user_login = 0;
      this.user_info = {};
    },
    //判断用户是否登录
    ifLogin(){
      //若存在用户信息
      if(window.localStorage.getItem("user_info")){
        this.user_login = 1;
        this.user_info = JSON.parse(window.localStorage.getItem("user_info")) 
      }else{
        this.user_login = 0;
        this.user_info = {};
      }
    },
    //根据id获得评论列表
    getCommentByArticleId(){
      this.$axios.get(this.$url.getCommentByArticleId + '?id='+ this.id).then(res=>{
        this.comment_list = res.data.data;
      })
    },
    //点赞接口
    giveFabulous(){
      this.$axios.get(this.$url.giveFabulous + '?id=' + this.id).then(res=>{
        this.$message.success(res.data.msg);
        console.log(this.article_info)
        this.article_info.like = this.article_info.like*1 + 1;
        this.fabulous = true;
      })
    },
    //去列表页
    goList(){
      this.$router.replace({path:'/list'});
    }
  }
}
</script>

<style scoped>
.article_body{
   width:1000px;
   background-color:#ffffff;
   border-radius:10px;
}
 /*去除最外层滚动条*/
.article_back::-webkit-scrollbar{
  display:none;
}

.color{
  color:rgb(138, 135, 135)
}
.return_btn{
  height:30px;
  width:110px;
  border-radius:20px;
  border:1px solid rgb(18, 172, 172);
  color:rgb(18, 172, 172);
  font-size:13px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.fabulous_btn{
  height:40px;
  width:80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:10px;
  cursor: pointer;
}
.submit_btn{
  background-color: rgb(18, 172, 172);
  height:30px;
  width:70px;
  text-align: center;
  line-height:30px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 5px;
}
</style>